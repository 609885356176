.loginPage {
    height: 100vh;
    background: #FFFFFF;
}

.loginPage .mh-100 {
    min-height: 100px;
}

.bg-auth {
    background: #F2C98A;
}

.bg-login-img {
    background-image: url(../svg/login-visual.svg);
    background-size: contain;
    background-position-x: center;
    background-position-y: bottom;
    height: 100vh;
    background-repeat: no-repeat;
}

.loginFormbox {
    width: 100%;
    max-width: 500px;
    padding: 1.75rem;
    box-shadow: 1px 1px 20px 2px #fecc4e;
}

.f-pass {
    color: #434343;
}

.loginFormbox .welcomeText {
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -0.84px;
    color: #fecc4e;
    margin-bottom: 30px;
}
.bg-auth h1 {
    color: #000;
    padding-top: 30%;
    text-align: center;
    margin-bottom: 8px;
}
.bg-auth h3{
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    color: #986923;
    line-height: 30px;
}
.ant-email {
   height: 50px;
}
.ant-input-password {
    height: 50px;
}
.box_footer {
    padding: 15px 0px;
    padding-top: 10px;
}
