/* dashboard css new  */
.dashboardBox {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
}

.priceText {
    color: #1559a2;
    font-size: 40px;
    font-weight: bold;
    margin-right: 20px;
}

.ct_chart_status::before {
    content: "";
    height: 5px;
    width: 20px;
    position: absolute;
    left: -24px;
    top: 9px;
}

.beforeActive::before {
    background: #fecc4e;
}

.beforeInactive::before {
    background: #1559a2;
}

/* users css */
.permissionBox {}

.permissionBox .largeCheckbox {
    width: 20px;
    height: 20px;
}

.permissionBox .permissionItem {
    margin: 0px 20px;
    margin-bottom: 20px;
}

.permissionBox .permissionItem .heading {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.permissionBox .permissionItem .permissionsList {
    /*  display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center; */
}

.permissionBox .permissionItem .permissionsList .inputBox {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}

.permissionBox .permissionItem .permissionsList .inputBox input {}

.permissionBox .permissionItem .permissionsList .inputBox .label {}

.colorDiv {
    height: 8px;
    width: 8px;
    border-radius: 2px;
    margin-top: 7px;
}

.centerLineGraphTime {
    color: #001737;
    padding: 3px;
    font-weight: 500;
    cursor: pointer;
}

.centerLineGraphTime.active {
    color: #000000;
    background: #E6E6E6;
    font-weight: bold;
}

.dashboardBox .boxHeadingTotal::after {
    content: "";
    width: 39px;
    height: 2px;
    border-radius: 25px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: all .4s ease;
    background: #1559a2;
}

.dashboardBox .boxHeadingRisk::after {
    content: "";
    width: 39px;
    height: 2px;
    border-radius: 25px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: all .4s ease;
    background: #FFB74D;
}

.dashboardBox .boxHeadingClasbi::after {
    content: "";
    width: 39px;
    height: 2px;
    border-radius: 25px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: all .4s ease;
    background: #EF5F5F;
}

.dashboardBox .boxHeadingAverage::after {
    content: "";
    width: 39px;
    height: 2px;
    border-radius: 25px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: all .4s ease;
    background: #2CD9C5;
}

.antdBoxSpiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
    height: 150px;
    width: 100%;
}