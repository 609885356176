@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap);
@import url('./Component/assets/css/common.css');
@import url('./Component/assets/css/layout.css');
@import url('./Component/assets/css/auth.css');
@import url('./Component/assets/css/dashboard.css');

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  font-size: 14px;
  color: #212529;
  margin: 0;
  padding: 0;
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */
/* .chatbatchpositionset */
.modelviewset .ant-modal-body {
  padding: 50px 39px 140px 24px!important;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.cardstudentdetail .ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 0px 0!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cardstudentdetail .ant-card{
box-shadow: 1px 1px 4px -1px;
}
.ant-row.antborder {
  border-top: 1px solid #80808078;
  padding-top: 5px;
}

.ant-select-dropdown {
   min-width: 310px!important;
    width: 410px!important;
    left: 380px!important;
    top: 0px!important;
}
.ant-btn {
  height:34px;
}

.setinfoposition1 {
  margin-top: -28px;
  margin-bottom: -32px;
  font-weight: 500;
  padding-left: 14px;
}
.clintchat input[type="text"] {
  width: 70%;
  padding: 5px 6px;
  position: fixed;
  bottom: 4px;
  border-radius: 1em;
  z-index: 2;
}
.imagebtn button {
  PADDING: 16px 19px;
  border-radius: 2em;
  border-color: #00000063;
  background-color: #fecc4e;
}
.prevewimg img{
border-radius: 2em;
width: 100%;
}
.grouplist a {
  text-decoration: none;
}
.mychathbatch li {
  display: inline-block;
}
li.chatbatchname {
  position: fixed;
  left: 22%;
  top: 14%;
  background-color: #fff;
}
.chat-container.clintchat {
  margin-top: 121px;
}

.mychathbatch {
  position: fixed;
  right: 2%;
}
.formbatch input {
  width: 90%;
  border: transparent;
  border-bottom:1px solid gray;
}
.formsubject input {
  margin-left: 34px;
  width: 84%;
  border: transparent;
  border-bottom:1px solid gray;
}
.ant-card {
  margin-bottom: 9%;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  background-color: #FFFFFF;
  border-radius: 10px;
}
/* .ant-modal-header{
  display: none!important;
} */
.layout_sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1559A2!important;
  color: #FFFFFF;
}
.dashboardBox {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  /* padding-top: 47px; */
  position: relative;
  top: -32px;
}
.ant-modal-body {
  padding: 0 24px 24px 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  margin-top: -22px;
}
.mybatchlist img {
  border-radius: 2em;
  margin-right: 14px;
}
.form-control {
  margin: 25px 24px 0 3px;
}
.loginPage .anticon svg {
  display: inline-block;
  position: relative;
  bottom: 63px;
  left: 360px;
}
.w-100 {
  width: 96%!important;
}
.ant-col-lg-24 {

  margin-top: 34px;
}
.ant-input-affix-wrapper>input.ant-input {
  padding: 13px;
  border: none;
  outline: none;
  right: 12px;
  width: 106%;
  border-radius: 5px;
  position: relative;
  bottom: 30px;
}
.clintchat button {
  border-radius: 1em;
  padding: 8px 45px;
  font-weight: 700;
  position: fixed;
  bottom: 4px;
  right: 12px;
  border: none;
  margin-left: 19px;
  color: #fff;
  z-index: 10000;
  background: linear-gradient(to right, rgb(254, 204, 78), #0dd00d);
  box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);
  /* background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe9800', endColorstr='#2e8bb9', GradientType=1 );
}
.clintchat1 input{
  position: fixed;
  bottom: 5px;
  width: 70%;
  padding: 5px 5px;
  border-radius: 10px;
}
label.fileInputLabel1 {
  position: fixed;
  bottom: 10px; 
  right: 19%;
  font-size: 17px;
}
.message.message-right p {
  background-color: #d7e8f6c4;
  padding: 3px 12px 3px 20px;
  border-radius: 2em;
  display: inline-block;
}
.messagetext {
  margin-top: 10%;
}
.message-right {
  text-align: right;
  max-width: 70%;
  margin-left: auto;
  word-wrap: break-word;
}
.messagetext {
  margin-top: 10%;
}
.clintchat span.anticon.anticon-paper-clip {
  position: fixed;
  z-index: 1000;
  bottom: 16px;
  right:153px;
  font-size: 19px;
}
.adminprofile .ant-modal-body{
     margin-top: 15px;
  }
  .batchstudymaterial.ant-select-dropdown {
    min-width: 310px!important;
    width: 509px!important;
    left: 57px;
    top: 231px!important;
    position:relative;
}
  .adminprofile .ant-card.ant-card-bordered {
    width: 510px;
    margin-left: -18px;
    margin-top: 32px;
    box-shadow: 0px 1px 0px 1px #80808038;
}
.logo.longlogo img {
  width: 30%;
}
.passchange input.ant-input {
  margin-top: 24px;
  border-radius: 8px;
}
.profiletopset .ant-modal-content {
  background: #FFFFFF;
  color: #212529;
  width: 888px;
}
.logout-btn {
  background-color: #1559a2;
  padding: 8px 10px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  bottom: 20px;
  left: 70px;
}
span.imgpreview {
  position: relative;
  /* left: 600px; */
  bottom:30px;
}
.col-4.profileimgadmin img {
  width: 50%!important;
  border-radius: 2em;
}
button.ant-btn.ant-btn-primary1 {
  background: linear-gradient(to right, rgb(254, 204, 78), #0dd00d);
  box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);
  border-radius: 3em;
  color: #fff;
  font-weight: 700;
  float: right;
}
span.imgpreview img {
  border-radius: 2em;
  background-color: #80808033;
  padding: 14px;
  
}
.creategroupposition {
  position: fixed;
  z-index: 1000;
  top: 80px;
  background-color: #fff!important;
}
.ant-row {
  margin-top: 5%;
}
.setinfoposition .ant-row {
  margin-top: 10%;
}
li.chatbatchname2 {
  margin-left: -29px;
}
.scrollmember{
white-space: nowrap;
overflow-x: auto;
position: fixed;
margin-top: 2%;
left: 10px;
/* bottom: 37px; */
left: 257px;
right: 42px;
}
.antborder1{
    padding-left: 37px!important;
}
.antborder2{
  padding-left: 4px!important;
}
.antborder3{
  padding-left: 16px!important;
}
.cardstudentdetail .ant-btn-primary {
  border:none;
  background:transparent;
  text-shadow: 0 0px 0 ;
   box-shadow: 0 0px 0 ;
   color:#000;
}
span.deletecomments {
  position: relative;
  left: 187px;
}
.row.commentsection p {
  background-color: #d7e8f6c4;
  padding: 2px 10px;
  border-radius: 2em;
  display: inline-block;
  max-width: 70%;
  word-wrap: break-word;
  display: inline-block;
}
span.profileimg {
  margin-right: 10px;
}
span.timesend {
  font-size: 7px;
  position: relative;
  top: 3px;
}
.row.commentsection {
  background-color: #d7e8f6c4;
  margin: 6px 0;
    border-radius: 1em;
    padding: 3px 0;
    min-height: 29px;
    height: 30px;
    max-height: 100px;
  
}
span.dategroup {
  text-align: center;
}
span.dategroup p {
  margin-top: 30px;
  background-color:#80808017;

  border-radius: 2em;
  padding: 2px 0px;
}
/* .row.commentsection {
  box-shadow: 5px -2px 12px -5px black;
} */
li.setinfoposition1 {
  display: inline-block;
  border-radius: 2em;
  /* background-color: forestgreen; */
  padding: 0;
  margin: -15px 6px;
}
.setinfoposition1 p{
background-color:rgb(254, 204, 78);
border-radius:2em;
padding: 0 0 0 10px;
}

.uldesign ul {
  padding-left: 1rem;
}
.ant-btn-danger {
  background:transparent;
  border:none;
  border-radius: 2em;
}
.ant-btn-danger:hover{
  background:transparent;
  border:none;
}
.btn-primary:hover {
  color: #fff;

  background: linear-gradient(to right, rgb(254, 204, 78), #0dd00d)!important;
  box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2)!important;
}
.remove-icon {
  width: 14px;
  margin-right: -9px;
  transition: width 0.3s ease; /* Smooth transition on width change */
}

.remove-icon:hover {
  width: 20px; /* Adjust the size as needed */
}
.ant-btn-danger button {
  background:transparent;
  border:none;
  height:5px!important;
}
/* li.chatbatchname2 {
  position: fixed;
  left: 248px;
  top:20px;
  padding: 4px 14px;
  margin-top: -11px;
  top: 127px;
  background:#fff;
  z-index:1000;
} */
li.chatbatchname3 {
background-color: rgb(254, 204, 78);
border-radius: 2em;
margin: 0 6px 0 0px;
font-size: 11px;
padding: 3px 10px;
}
.cardstudentdetail  p span{
font-weight:700;
}
.cardstudentdetail p{
font-size:13px;
}
.grouplistinfo.ant-card-body {
  display: none;
}
.chatinfocard .ant-card-head {
  box-shadow: 1px 1px 4px -1px;
}
a {
  text-decoration: none!important;
}
.setinfoposition {
  margin-top: -29px;
  margin-bottom: -52px;
}
.ant-card-head {
  min-height: 14px;
  margin-bottom: -1px;
  padding: 0 10px;
}
.perastyle{
padding-left: 14px;
padding-right: 8px;
margin-top: -7px;
}
input.ant-input.form-control {
  margin-top: -1px;
}
.chatinfocard .ant-card-body {
  display: none;
}
.setinfoposition p img{
  margin-top: -46px;
  width:20px; 
  margin-left: 10px;
  position: relative;
  right: 11px;
}.userlisticon  {
  border: none;
  margin-right: 24px;
  position: relative;
  right: 15px;
  background-color:#fff;
}
.userlisticon:hover {
  color: #fff;
  border-color: #fff;
  background: #fff;
}
span.username {
  margin-right: 8px;
  position: relative;
  bottom: 16px;
  font-size: 11px;
}
span.username img{
  position: relative;
  top: 14px;
  left: 24px;
  }
.userlisticon:focus, .userlisticon:hover {
  color: #fff;
  border-color: #fff;
  background: #fff;
}
.gradient-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

.transparent-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.filter-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

.clear-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid #fecc4e;
}

.scale-icon {
  max-width: 18px;
  height: auto;
}

.ant-modal-title {
  color: #212529;
}

.ant-modal-content {
  background: #FFFFFF;
  color: #212529;
}

.ant-select {
  color: #212529 !important;
  outline: none !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: none;
}

.ant-select-selector {
  min-height: 35px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.status_active {
  font-size: 12px;
  font-weight: 700;
  color: #0E6F6B;
  background-color: #C9F7F5;
  border-color: #C9F7F5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive {
  font-size: 12px;
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive:hover {
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  cursor: pointer;
}

.edit-icon {
  max-width: 30px;
  height: auto;
  font-size: 25px;
  color: #1559a2;
}

.faq-icon {
  width: 50px;
  font-size: 25px;
  padding-left: 15px;
  color: #1559a2;
}

input[readonly] {
  background-color: #f7f7f7;
  border: unset;
}

input[readonly]:hover,
input[readonly]:focus {
  border: unset;
  box-shadow: unset;
}

.ant-menu-submenu-selected {
  fill: #1559A2;
}

.clear-button1 {
  z-index: 99;
}

.table tbody td {
  font-weight: 500;
  max-width: fit-content;
  min-width: auto;
  font-size: 13px !important;
}

.table thead th {
  font-size: 13px !important;
}

.ant-pagination-options {
  display: none;
}

.ant-form-item-control-input-content .wrapper {
  z-index: 999 !important;
}

.cmyNjn>.wrapper {

  /* height: 36px !important; */
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  min-height: 36px !important;
}

.cmyNjn>.wrapper:active {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.cmyNjn>.wrapper:hover {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.fDbOPw {
  min-height: 36px !important;
}

div#testid_erro {
  color: red;
}

.appli_view_label {
  font-size: 14px;
  font-weight: 500;
}

.appli_view {
  margin-top: 15px;
}

.job-post-add {
  position: unset;
  display: block;
  margin: auto;
  margin-right: 2%;
}
.cardstudentdetail .ant-card-head {
  min-height: 14px;
  margin-bottom: -1px;
  background-color: #18c60594;
  padding: 5px 10px;
  color: #fff;
  font-weight: 700;
  border-radius: 6px 8px 0 0;
}
.cardstudentdetail .ant-card-body {
  padding: 0px 13px 9px 13px;
}
span.deleteicon img {
  position: relative;
  left: 132px;
  bottom: 1px;
}
/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
} */

.ex-export span.anticon.anticon-file-excel {
  font-size: 30px;
  color: #109d59;
  display: inline-block;
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.ex-export {
  position: absolute;
  right: 5%;
  bottom: 5px;
}

.btn-exl {
  background: none;
  border: 0px;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.view-attachment {
  width: 20px;
  font-size: 25px;
  margin: 0 4px;
  padding-top: 0;
}

.bg_gray {
  background: #f7f7f7;
  height: 200px;
  border: none;
}

.doc_icon {
  width: 40px;
}

.doc_icon-w {
  width: 25px;
}

.table_first_col {
  width: 70px !important;
}

.table_action_col {
  width: 150px !important;
}

.table_status_col {
  width: 150px !important;
}

.jop_btn {
  background-color: #1559a2;
  padding: 5px 15px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.jop_btn svg {
  width: 20px;
  margin-left: 2px;
  height: 22px;
  vertical-align: middle;
}

.char_count {
  float: right;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.d-none {
  display: none;
}
.c-bl{
  color: #1890ff;
}
.frameSet{
  padding: 20px 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}